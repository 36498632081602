import { EventDispatcher, add14Days, deepCopy, filterObjectByKeys, getNodeIndex, getUrlVars, hideElement, isDesktopView, isHomePage, isMobileView, l, lLink, logEvent, qs, qsa, showElement, sleep, slideDown, slideReset, slideUp, storedItemCreate, storedItemRead, urlParameterDelete } from '@utils/toolbox';
import { dateDifferenceInDays, dateFormat, dateFormatYMD, dateGetDate, dateGetDay, dateGetMonth, dateIsAfter, dateIsBefore, dateIsSameDay, dateParseISO, dateShortWeekDaysArray, dateStartOfDay } from '@utils/dateFns';
import { Base64 } from '@js/plugins/base64';
import { ConnectApi } from '@js/plugins/connectApi';
import { DatePicker } from '@components/datepicker';
import { DateSelector } from '@components/dateselector';
import { LoadingOverlay } from '@module/LoadingOverlay/LoadingOverlay';
import { SearchFieldMulti } from '@module/SearchFieldMulti/SearchFieldMulti';
import { SearchProgressBox } from '@components/searchProgressBox';
import { Modal } from '@js/components/modal';
import { eventCategories, events } from '@js/utils/event';

const _defaults = {
	id: 'abSearchBox',
	default: true,
	dates: {
		today: js_params.isoDates.today,
		from: js_params.isoDates.fromIndex,
		to: js_params.isoDates.toIndex
	},
	maxPassengers: 9,
	passengerTypesPerView: {
		default: ['adult', 'child'],
		youth: ['youth']
	},
	maxAges: { child: 12, youth: 25 },
	passengerTypeTranslations: {
		adult: { singular: l('searchBox.passengers.adult'), plural: l('searchBox.passengers.adults') },
		child: { singular: l('searchBox.passengers.child'), plural: l('searchBox.passengers.children') },
		youth: { singular: l('searchBox.passengers.youth'), plural: l('searchBox.passengers.youthPlural') }
	},
	passengersViewsData: {
		default: {
			adults: 1,
			child: {
				count: 0,
				ages: []
			}
		},
		youth: {
			youth: {
				count: 0,
				ages: []
			}
		}
	},
	availablePassengersViews: ['default'],
	selectedPassengersView: 'default',
	activePassengersView: 'default',
	config: {
		tripType: 1, /* return */
		cabin: 0,
		cabinBaggage: 0,
		legs: [],
		nearbyDates: 0,
		baggage: 0,
		passengers: {},
		direct: 0,
		climateComp: 0,
		flexTicket: 0
	},
	leg: {
		fromIatas: [],
		fromIata: '',
		from: '',
		toIatas: [],
		toIata: '',
		to: '',
		departDate: js_params.isoDates.fromIndex,
		returnDate: js_params.isoDates.toIndex,
		fromMetro: false,
		toMetro: false
	},
	userSavedSearch: storedItemRead('userSavedSearch'),
	dateSelector: {
		departTitle: `<span>${l('dateselector.title.chooseDepartureDate')}</span>`,
		returnTitle: `<span>${l('dateselector.title.chooseReturnDate')}</span>`
	},
	errors: {
		departNotSet: l('searchBox.error.notSetDeparture'),
		returnNotSet: l('searchBox.error.notSetDestination'),
		dateNotSet: l('searchBox.error.notSetDate'),
		departIsReturn: l('searchBox.error.sameDepartureAndDestination'),
		passengersNotSet: l('searchBox.error.passengersNotSet'),
		childAgesNotSet: l('searchBox.errors.childrenMissingAges'),
		youthAgesNotSet: l('searchBox.errors.youthMissingAges'),
		departDateIsAfterReturn: l('searchBox.error.departDateIsAfterReturn'),
		datesAreInThePast: l('searchBox.error.datesAreInThePast')
	}
};

export class SearchBox {
	constructor (opts) {
		window.searchBox = this;
		this.id = opts.id;
		this.passengerTypeTranslations = opts.passengerTypeTranslations || _defaults.passengerTypeTranslations;
		this.html = document.getElementById(this.id);
		this.dateSelector = null;
		this.datePickers = null;
		this.isUserSavedSearch = (_defaults.userSavedSearch) ? true : false;
		this.showresultMode = opts.showresultMode || false;
		this._callbacks = opts.callbacks || {};
		this.showresultURL = '/';
		this.resultCount = 0;
		this.request = null;
		this.isSearching = false;
		this.searchFields = [];
		this.maxNumberOfLegs = 5;
		this.isYouthTicketEnabled = js_params.module?.SearchBox?.isYouthTicketEnabled || false;
		this.selectedPassengersView = opts.selectedPassengersView || _defaults.selectedPassengersView;
		this.availablePassengersViews = _defaults.availablePassengersViews;
		this.passengerTypes = _defaults.passengerTypesPerView[this.selectedPassengersView];
		this.initialScrollPos = 0;
		this.isCarbon = opts.isCarbon;

		// Set up default passengers selectors
		if (this.isYouthTicketEnabled) {
			this.availablePassengersViews.push('youth');
		}

		this.passengersViewsData = filterObjectByKeys(_defaults.passengersViewsData, type => this.availablePassengersViews.includes(type));
		_defaults.config.passengers = Object.assign({}, _defaults.passengersViewsData[this.selectedPassengersView]);

		// duck tape fix
		this.nullCount = 0;

		if (opts.config) {
			if ('legs' in opts.config) {
				for (let i = 0; i < opts.config.legs.length; i++) {
					opts.config.legs[i] = Object.assign({}, _defaults.leg, opts.config.legs[i]);
				}
			} else {
				opts.config.legs = [_defaults.leg];
			}

			this.data = Object.assign({}, _defaults.config, opts.config);
			this.syncPassengersTypesAndViewWithData();
		} else {
			if (this.isUserSavedSearch && isHomePage()) {
				this.setDataFromLocalStorage();
			} else {
				// there is no configuration set and no cookie exists - so create an empty leg and set default params.
				this.data = _defaults.config;

				this.data.legs.push(_defaults.leg);
			}
		}

		// Here we check if the dates of the search data are in the past.
		// If they are, we reset them...
		// Might be ok to delete this section as a similar operation is done in the in setDataFromLocalStorage

		if (typeof this.data.legs !== 'undefined') {
			this.data.legs.forEach((leg) => {
				const today = new Date();
				if (dateIsBefore(dateStartOfDay(dateParseISO(leg.departDate)), dateStartOfDay(today))) {
					leg.departDate = _defaults.dates.from;

					if (this.data.tripType === 1) {
						leg.returnDate = _defaults.dates.to;
					}
				}
			});
		}

		// Sort children and youth ages in ascending order
		this.sortPassengersAges();

		this.initialData = deepCopy(this.data);

		this.initDateSelector(opts.dateSelector);
		this.initDatePicker();

		if (!this.isCarbon) {
			this.setDateSelectorView();
		}

		this.addLegs();
		this.events();

		if (!this.isCarbon) {
			this.setView();
			this.cacheElems();
			this.initLoadingOverlay();

			if (this.isUserSavedSearch) {
				this.setCheckboxes();
				this.setPassengers();
				this.setCabin();
			} else if (opts.config) {
				this.setPassengers();
				this.setCabin();
				this.setCheckboxes();
			}
		}

		const urlVars = getUrlVars();

		if (this.showresultMode) {
			const nav = this.html.getElementsByClassName('abSearchBox__nav')[0];

			if (nav) {
				nav.classList.add('abTabNav--transparent');
			}

			if (urlVars.id) {
				this.showresultURL = `/${lLink('global.navigation.resultsLink')}?id=${urlVars.id}`;
			}
		} else {
			if (urlVars.direct && parseInt(urlVars.direct, 10) === 1) {
				this.data.direct = 1;
				this.setDirectCheckbox();
			}

			if (urlVars.climateCompensated && parseInt(urlVars.climateCompensated, 10) === 1) {
				this.data.climateComp = 1;
				this.setClimateCompCheckbox();
			}

			if (urlVars.flexTicket && parseInt(urlVars.flexTicket, 10) === 1) {
				this.data.flexTicket = 1;
				this.setFlexTicketCheckbox();
			}

			if (urlVars.baggage && parseInt(urlVars.baggage, 10) === 1) {
				this.data.baggage = 1;
				this.setBaggageCheckbox();
			}

			if (urlVars.cabinBaggage && parseInt(urlVars.cabinBaggage, 10) === 1) {
				this.data.cabinBaggage = 1;
				this.setCabinBaggageCheckbox();
			}
		}

		if (urlVars.showNearbyDatesModal === '1') {
			this.showErrorModal({ error_code: 'E8' });
			urlParameterDelete('showNearbyDatesModal');
		}

		return this;
	}

	initLoadingOverlay () {
		window.LoadingOverlay = new LoadingOverlay();
	}

	initDateSelector (dateSelector) {
		this.dateSelector = new DateSelector(
			{
				viewType: 'datepickers',
				html: dateSelector,
				callbacks:
				{
					onShow: () => {
						if (isMobileView()) {
							this.initialScrollPos = window.scrollY;
							if (this.showresultMode) {
								this.setFixedStyles();
							}
						}
					},
					onHide: () => {
						if (isMobileView()) {
							window.scrollTo({ top: this.initialScrollPos, behavior: 'smooth' });
						}
						if (this.datePickers.tripType === 1) {
							if (this.datePickers.departSelected) {
								this.datePickers.departSelected = false;

								const departDateObject = new Date(this.datePickers.departDate);
								const returnDateObject = new Date(this.datePickers.returnDate);

								const departIsBeforeReturn = dateIsBefore(departDateObject, returnDateObject);
								const datesAreTheSame = dateIsSameDay(departDateObject, returnDateObject);

								if (departIsBeforeReturn || datesAreTheSame) {
									this.setDateField(this.datePickers.departField, this.datePickers.departDate);
									this.setDateField(this.datePickers.returnField, this.datePickers.returnDate);
								}
							}
						}

						this.blurFields();

						if (this.showresultMode) {
							this.removeFixedStyles();
						}
					},
					onReset: () => {
						this.datePickers.resetDates();
					},
					onSlide: (direction) => {
						scrollDatePickers(direction);
					}
				}
			});
	}

	initDatePicker () {
		this.datePickers = new DatePicker(
			{
				searchBox: this,
				callbacks:
				{
					onDepartDatePicked: (instance) => {
						const { departField, departDate, returnField, returnDate } = instance;

						const departDateObject = new Date(departDate);
						const returnDateObject = new Date(returnDate);
						const departIsAfterReturn = dateIsAfter(departDateObject, returnDateObject);
						const datesAreTheSame = dateIsSameDay(departDateObject, returnDateObject);

						// Check for returnField since isAfter will return true when returnDateObject is null
						if (returnField && (departIsAfterReturn || datesAreTheSame)) {
							this.setDateField(returnField, departDate);
						}

						this.setDateField(departField, departDate);
						logEvent(events.editsearchDateDeparture, eventCategories.search, departDate);

						if (instance.tripType !== 1) {
							setTimeout(() => this.dateSelector.hide(), 400);
						} else {
							this.dateSelector.setTitle(_defaults.dateSelector.returnTitle);

							this.blurFields();
							this.focusField(returnField, 'abSearchBox__row--focus-return');

							if (this._callbacks.onReturnDateFocused) {
								this._callbacks.onReturnDateFocused(this);
							}
						}
					},
					onReturnDatePicked: (instance) => {
						const { departField, departDate, returnField, returnDate } = instance;
						const departDateObject = new Date(departDate);
						const returnDateObject = new Date(returnDate);
						const departIsBeforeReturn = dateIsBefore(departDateObject, returnDateObject);
						const datesAreTheSame = dateIsSameDay(departDateObject, returnDateObject);

						if (departIsBeforeReturn || datesAreTheSame) {
							this.setDateField(departField, departDate);
						}

						this.setDateField(returnField, returnDate);
						logEvent(events.editsearchDateDeparture, eventCategories.search, returnDate);

						if (instance.tripType === 1) {
							setTimeout(() => this.dateSelector.hide(), 400);
						}
					},
					onScroll: () => {
						scrollDatePickers();
					}
				}
			});
	}

	setDataFromLocalStorage () {
		// Check if the departure date is in the past, if so, set departure date a week from today.
		// If return trip, set the return date 14 days from today.
		// If Multi City, repeat date changes, but for each leg.

		const userSavedSearch = storedItemRead('userSavedSearch');
		const tripType = userSavedSearch.tripType;
		const departDateObject = dateParseISO(userSavedSearch.legs[0].departDate);
		const now = dateStartOfDay(new Date());

		if (dateIsBefore(departDateObject, now)) {
			for (const leg of userSavedSearch.legs) {
				leg.departDate = _defaults.dates.from;

				if (tripType === 1) {
					leg.returnDate = _defaults.dates.to;
				}
			}
		}

		this.data = Object.assign(_defaults.config, userSavedSearch);
		this.syncPassengersTypesAndViewWithData();
	}

	syncPassengersTypesAndViewWithData () {
		if ('youth' in this.data.passengers) {
			if (this.isYouthTicketEnabled) {
				this.selectedPassengersView = 'youth';
			} else {
				// Automatically change passengerViews if Youth ticket are no longer available to selection
				this.selectedPassengersView = 'default';
				this.data.passengers = Object.assign({}, _defaults.passengersViewsData.default, { adults: this.data.passengers.youth.count });
			}
		} else {
			this.selectedPassengersView = 'default';
		}
		this.passengerTypes = _defaults.passengerTypesPerView[this.selectedPassengersView];
	}

	addLegs () {
		const legs = this.html.getElementsByClassName('abSearchBox__leg');
		const leg = legs[legs.length - 1];
		const dataTripType = this.data.tripType;
		const dataLegs = this.data.legs;

		if (dataLegs.length) {
			// we have pre-defined search data - either from cookie or config...

			let field = leg.getElementsByClassName('abSearchBox__depart')[0];
			let date = dataLegs[0].departDate || _defaults.dates.from;

			this.setupDateField({ field, date });

			if (dataTripType === 1) {
				field = leg.getElementsByClassName('abSearchBox__return')[0];
				date = dataLegs[0].returnDate || _defaults.dates.to;

				this.setupDateField({ field, date });
			} else if (dataTripType === 2) {
				const numOfLegs = this.data.legs.length - 1;

				for (let i = 1; i <= numOfLegs; i++) {
					this.renderMultiCityLeg(i);
				}
			}
		} else {
			let field = leg.getElementsByClassName('abSearchBox__depart')[0];
			let date = _defaults.dates.from;

			this.setupDateField({ field, date });

			if (dataTripType === 1) {
				field = leg.getElementsByClassName('abSearchBox__return')[0];
				date = _defaults.dates.to;

				this.setupDateField({ field, date });
			}
		}

		// Add iata Searchfields
		const iataFields = this.html.getElementsByClassName('iata_field_module');
		for (let i = 0; i < iataFields.length; i++) {
			this.addSearchField(iataFields[i], Math.floor(i / 2));
		}

		// Set initial checked iatas
		if (this.data.legs.length) {
			this.setInitialCheckedIatas();
		}
	}

	focusField (field, style) {
		const row = field.parentNode.closest('.abSearchBox__row');

		if (row.classList.contains(style)) {
			return false;
		}

		this.html.classList.add('abSearchBox--show-overlay');

		row.classList.add(style);

		if (isMobileView()) {
			if (this.showresultMode) {
				const overlay = this.html.getElementsByClassName('abSearchBox__overlay')[0];
				const searchBoxHeight = document.getElementsByClassName('abShowResultSearchBox__inner')[0].clientHeight;

				overlay.style.height = Math.max(window.innerHeight, searchBoxHeight) + 'px';
			}

			document.body.style.overflow = 'hidden';

			window?.recentSearches?.stop?.();
		}

		return true;
	}

	blurFields () {
		const rows = this.html.getElementsByClassName('abSearchBox__row');
		const focusStyles = ['from', 'to', 'depart', 'return', 'passengers', 'cabin', 'view'];

		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];

			for (let j = 0; j < focusStyles.length; j++) {
				row.classList.remove('abSearchBox__row--focus-' + focusStyles[j]);
			}
		}

		this.html.classList.remove('abSearchBox--show-overlay');

		if (isMobileView()) {
			const overlay = this.html.getElementsByClassName('abSearchBox__overlay')[0];

			if (this.showresultMode) {
				overlay.style.height = '';
			}

			document.body.style.overflow = '';

			setTimeout(() => {
				window.scrollTo({ top: this.initialScrollPos, behavior: 'smooth' });
			}, 60);
		}
	}

	renderMultiCitySummary () {
		const multiSummary = this.html.querySelector('.abSearchBox__multi-summary');
		const legsNb = this.data.legs.length;

		const isAllNumeric = str => str.match(/^[0-9]+$/);
		const truncateString = (str, num) => str.length > num ? str.slice(0, num) + '...' : str;

		let html = '';
		const ratio = 1 / legsNb;
		const maxW = `calc(${ratio}*(100% - ${legsNb}*9px))`;

		this.data.legs.forEach((leg, index) => {
			const departure = (legsNb == 2 && isDesktopView()) ? leg.from : (leg.fromIata && isAllNumeric(leg.fromIata)) ? truncateString(leg.from, 5) : leg.fromIata;
			const arrival = (legsNb == 2 && isDesktopView()) ? leg.to : (leg.toIata && isAllNumeric(leg.toIata)) ? truncateString(leg.to, 5) : leg.toIata;
			const legDepartDateObject = new Date(leg.departDate);
			const dateExtra = ((legsNb <= 3)) ? `, <small>${dateFormat(legDepartDateObject, 'eee')}</small>` : '';

			html += `<div class="abSearchBox__multi-summary--leg" style="max-width:${maxW}">
							<span class="abSearchBox__label">Flight ${index + 1}</span>
							<span class="abSearchBox__value"> ${(departure) ? departure : ''} <span class="icon icon-arrow-right"></span> ${(arrival) ? arrival : ''} <span class="abSearchBox__multi-summary--date">${dateFormat(legDepartDateObject, 'MMM d')}${dateExtra}</span></span>
						</div>`;
		});

		multiSummary.innerHTML = html;
	}

	renderMultiCityLeg (legIndex = null) {
		const legs = this.html.getElementsByClassName('abSearchBox__leg');
		const previousLeg = legs[legs.length - 1];
		const newLeg = previousLeg.cloneNode(true);

		const newLegIndex = legIndex || parseInt(previousLeg.dataset.legIndex, 10) + 1;
		const legData = this.data.legs[newLegIndex];
		let newLegIataFields;

		newLeg.dataset.legIndex = newLegIndex;
		newLeg.getElementsByClassName('abSearchBox__circle--number')[0].textContent = newLegIndex + 1;

		this.setupDateField({ field: newLeg.getElementsByClassName('abSearchBox__depart')[0], date: legData ? legData.departDate : _defaults.dates.from });

		newLegIataFields = newLeg.getElementsByClassName('iata_field_module');

		for (let i = 0; i < newLegIataFields.length; i++) {
			this.addSearchField(newLegIataFields[i], newLegIndex);
		}

		// keep tabindex consistent across input fields
		const previousLegInput = previousLeg.querySelectorAll('input.SearchFieldMulti__field')[1];
		let lastTabIndex = parseInt(previousLegInput.getAttribute('tabindex'), 10);
		const newLegInputs = newLeg.querySelectorAll('input.SearchFieldMulti__field');
		newLegInputs[0].setAttribute('tabIndex', ++lastTabIndex);
		newLegInputs[1].setAttribute('tabIndex', ++lastTabIndex);

		this.html.insertBefore(newLeg, previousLeg.nextElementSibling);

		qs('#ab-showresult-dateselector-search-box', newLeg)?.remove?.();
		qs('#ab-dateselector-index', newLeg)?.remove?.();
		if (this.isCarbon) {
			qsa('.emission-aircraft-input', newLeg).forEach((inputElement) => {
				inputElement.value = '';
				inputElement.removeAttribute('data-value');
			});
		}
		// this.updateLegs();
	}

	renderMultiCityLegNumbers () {
		const legs = this.html.getElementsByClassName('abSearchBox__leg');

		for (let i = 0; i < legs.length; i++) {
			const leg = legs[i];
			const circle = leg.getElementsByClassName('abSearchBox__circle--number')[0];

			leg.dataset.legIndex = i;
			circle.textContent = i + 1;
		}
	}

	renderMultiCityRemoveBtns () {
		const legs = this.html.getElementsByClassName('abSearchBox__leg');

		if (legs.length > 2 || (this.isCarbon && legs.length > 1)) {
			qsa('.abSearchBox__remove-leg').forEach((btn) => {
				btn.classList.add('abSearchBox__remove-leg--visible');
			});
		} else {
			qsa('.abSearchBox__remove-leg').forEach((btn) => {
				btn.classList.remove('abSearchBox__remove-leg--visible');
			});
		}
	}

	addSearchField (field, leg = 0) {
		const bound = field.dataset.bound;

		// Check if bound is already set
		if (this.searchFields?.[leg]?.[bound] && this.searchFields[leg][bound].inputElement == field) {
			return;
		}
		const tabIndexOffset = bound === 'from' ? 1 : 2;
		const tabIndex = leg + tabIndexOffset;

		this.searchFields[leg] = this.searchFields[leg] || {};
		this.searchFields[leg][bound] = new SearchFieldMulti({
			searchBox: this,
			tabIndex,
			inputElem: field
		});
	}

	setInputFields (opts) {
		const { fromVal, fromData, fromMetro } = opts;
		const { toVal, toData, toMetro } = opts;

		const fields = opts.leg.getElementsByTagName('input');
		const fromField = fields[0];
		const toField = fields[fields.length - 1];

		fromField.dataset.iata = fromData;
		fromField.dataset.metro = fromMetro;
		fromField.value = fromVal;

		toField.dataset.iata = toData;
		toField.dataset.metro = toMetro;
		toField.value = toVal;
	}

	setInitialCheckedIatas () {
		if (!this.data.legs.length) {
			return;
		}

		const validCheckedIata = ci => (
			typeof ci === 'object'
			&& ci.iata && ci.iata.length === 3
			&& typeof ci.isMetro === 'boolean'
			&& ci.text && ci.text.length > 0
		);

		this.data.legs.forEach((leg, index) => {
			// For backword compatibilit y:
			// Fallback to the old structure of legs.
			let fromCheckedIatas = leg.fromIatas || [
				{
					iata: leg.fromIata,
					text: leg.from,
					isMetro: !!leg.fromMetro
				}
			];

			let toCheckedIatas = leg.toIatas || [
				{
					iata: leg.toIata,
					text: leg.to,
					isMetro: !!leg.toMetro
				}
			];

			fromCheckedIatas = fromCheckedIatas.filter(validCheckedIata);
			toCheckedIatas = toCheckedIatas.filter(validCheckedIata);

			this.setCheckedIatas(fromCheckedIatas, toCheckedIatas, index);
		});
	}

	setCheckedIatas (fromCheckedIatas = [], toCheckedIatas = [], legIndex = 0) {
		this.searchFields[legIndex].from.setCheckedIatas(fromCheckedIatas);
		this.searchFields[legIndex].to.setCheckedIatas(toCheckedIatas);
	}

	setView () {
		const iatasLimit = (this.data.tripType === 2) ? 1 : null;
		const nav = this.html.getElementsByClassName('abSearchBox__nav')[0];
		const btns = nav.children;

		for (let i = 0; i < btns.length; i++) {
			btns[i].classList.remove('abTabNav__btn--active');
		}

		if (this.data.tripType === 0) {
			// oneway

			this.html.classList.remove('abSearchBox--multi');
			this.html.classList.remove('abSearchBox--return');
			this.html.classList.add('abSearchBox--oneway');

			btns[0].classList.add('abTabNav__btn--active');
		} else if (this.data.tripType === 2) {
			// multi

			const legs = this.html.getElementsByClassName('abSearchBox__leg');

			this.html.classList.remove('abSearchBox--oneway');
			this.html.classList.remove('abSearchBox--return');
			this.html.classList.add('abSearchBox--multi');

			btns[2].classList.add('abTabNav__btn--active');

			if (legs.length === 1 && !this.isCarbon) {
				this.renderMultiCityLeg();
			}

			this.renderMultiCityRemoveBtns();

			if (this.showresultMode) {
				this.renderMultiCitySummary();
			}
		} else {
			// return

			this.html.classList.remove('abSearchBox--multi');
			this.html.classList.remove('abSearchBox--oneway');
			this.html.classList.add('abSearchBox--return');

			btns[1].classList.add('abTabNav__btn--active');
		}

		this.setViewField();
		this.setDateSelectorView();
		this.setFieldsMaxIatas(iatasLimit);
	}

	cacheElems () {
		const passengers = this.html.getElementsByClassName('abSearchBox__passengers')[0];
		const passengersAllAgeGroup = passengers.getElementsByClassName('abPassengers__group--ages');
		const passengersAllAgeRange = passengers.getElementsByClassName('abPassengers__ranges');
		const passengersAllAgeRanges = passengers.getElementsByClassName('abPassengers__range');
		const passengersAllAgeBlocks = passengers.getElementsByClassName('abPassengers__age');
		const passengersApplyBtn = passengers.getElementsByClassName('abPassengers__applyBtn')[0];
		const passengersMaxAgeMsg = passengers.getElementsByClassName('abPassengers__msg')[0];

		this.elems = {
			passengers,
			passengersAllAgeGroup,
			passengersAllAgeRange,
			passengersAllAgeRanges,
			passengersAllAgeBlocks,
			passengersApplyBtn,
			passengersMaxAgeMsg
		};
	}

	setViewField () {
		const viewField = this.html.querySelector('.abSearchBox__view');
		const fieldValue = viewField.querySelector('.abSearchBox__value');
		const buttons = viewField.querySelectorAll('[data-action="set-view"]');

		buttons.forEach((button) => {
			if (parseInt(button.dataset.type, 10) === this.data.tripType) {
				button.classList.add('abBtn--ticked');

				fieldValue.innerHTML = button.querySelector('span').innerText;
			} else {
				button.classList.remove('abBtn--ticked');
			}
		});
	}

	setCabin () {
		const buttons = this.html.querySelectorAll('button[data-action="set-cabin"]');

		buttons.forEach((button) => {
			if (parseInt(button.value, 10) === this.data.cabin) {
				button.click();
			}
		});
	}

	setDirectCheckbox () {
		const checkboxes = this.html.querySelectorAll('input.checkbox-direct');
		checkboxes.forEach(checkbox => checkbox.checked = this.data.direct);
		// checkboxes[0].dispatchEvent(new Event('change'));
	}

	setBaggageCheckbox () {
		const checkbox = this.html.getElementsByClassName('abSearchBox__baggage')[0];
		checkbox.checked = this.data.baggage;
	}

	setCabinBaggageCheckbox () {
		const checkbox = this.html.getElementsByClassName('abSearchBox__cabinBaggage')[0];
		checkbox.checked = this.data.cabinBaggage;
	}

	setClimateCompCheckbox () {
		const checkbox = this.html.getElementsByClassName('abSearchBox__climateComp')[0];
		checkbox.checked = this.data.climateComp;
	}

	setFlexTicketCheckbox () {
		const checkbox = this.html.getElementsByClassName('abSearchBox__flexTicket')[0];
		checkbox.checked = this.data.flexTicket;
	}

	setNearbyCheckbox () {
		const checkbox = this.html.getElementsByClassName('abSearchBox__nearby')[0];
		checkbox.checked = this.data.nearbyDates;
	}

	setDateField (field, strDate) {
		const date = dateParseISO(strDate);
		const monthDay = dateFormat(date, 'MMM d');
		const dayName = dateFormat(date, 'EEE');
		const value = `${monthDay}, <small>${dayName}</small>`;

		field.dataset.date = dateFormatYMD(date);
		field.getElementsByClassName('abSearchBox__value')[0].innerHTML = value;

		this.updateLegs();

		if (this.data.tripType === 1) {
			this.setDurationLabel();
		}

		return this;
	}

	setDurationLabel () {
		const durationLabel = this.html.getElementsByClassName('abSearchBox__duration')[0];

		const days = dateDifferenceInDays(new Date(this.data.legs[0].returnDate), new Date(this.data.legs[0].departDate));

		const dayTranslation = days > 1 ? l('global.calendar.days') : l('global.calendar.day');

		if (days === 0) {
			hideElement(durationLabel);
		} else if (durationLabel.style.display === 'none') {
			showElement(durationLabel);
		}
		durationLabel.children[1].innerHTML = `${days} ${dayTranslation}`;
	}

	scrollTopView (elem, offset) {
		this.initialScrollPos = window.scrollY;
		const elemOffset = elem.getBoundingClientRect().top + window.scrollY - offset;

		window.scrollTo({ top: elemOffset });
	}

	updateLegs () {
		const legs = this.html.getElementsByClassName('abSearchBox__leg');

		this.data.legs = [];

		for (let i = 0; i < legs.length; i++) {
			const leg = legs[i];

			const departDate = leg.getElementsByClassName('abSearchBox__depart')[0].dataset.date;
			let returnDate;

			if (this.data.tripType === 1) {
				returnDate = leg.getElementsByClassName('abSearchBox__return')[0].dataset.date;
			}

			const fromIatas = this.searchFields[i].from.getCheckedIatas();
			const toIatas = this.searchFields[i].to.getCheckedIatas();

			const data = {};
			data.fromIatas = fromIatas;
			data.toIatas = toIatas;
			data.departDate = departDate;
			data.returnDate = returnDate;

			// For backword compatibility add from, to, fromIata, toIata, fromMetro, toMetro
			// We take the first from and first to and use it to fill these fields

			if (fromIatas.length !== 0) {
				const { text: from, iata: fromIata, isMetro: fromMetro } = fromIatas[0];
				data.from = from;
				data.fromIata = fromIata;
				data.fromMetro = fromMetro;
			}

			if (toIatas.length !== 0) {
				const { text: to, iata: toIata, isMetro: toMetro } = toIatas[0];
				data.to = to;
				data.toIata = toIata;
				data.toMetro = toMetro;
			}

			this.data.legs.push(data);

			if (this.data.tripType !== 2) {
				break;
			}
		}

		if (this.showresultMode && this.data.tripType === 2) {
			this.renderMultiCitySummary();
		}

		return this;
	}

	setupDateField (params) {
		if (!this.isCarbon) {
			const { field, date } = params;
			const fieldVal = field.getElementsByClassName('abSearchBox__value')[0];

			if (date) {
				const dateObject = dateParseISO(date);
				// get month number and day number
				const monthNumber = dateGetMonth(dateObject);
				const dayNumber = dateGetDay(dateObject);
				const monthName = js_params.months[monthNumber];
				const dayName = dateShortWeekDaysArray[dayNumber];
				const monthDate = dateGetDate(dateObject);

				field.dataset.date = dateFormatYMD(dateObject);
				fieldVal.innerHTML = `${monthName} ${monthDate}, <small>${dayName}</small>`;
			} else {
				field.dataset.date = '';
				fieldVal.innerHTML = '&nbsp;';
			}

			if (this.data.tripType === 1) {
				this.setDurationLabel();
			}

			return this;
		}
	}

	showLoader () {
		console.trace('dispatching loadingOverlay');
		EventDispatcher.dispatch('loadingOverlay', true);
	}

	hideLoader () {
		EventDispatcher.dispatch('loadingOverlay', false);
	}

	validateData () {
		let success = true;
		let errorOrigin = 'legs';
		let message = 'none';

		if (this.isYouthTicketEnabled && this.selectedPassengersView === 'youth') {
			// check if adult passenger number is below 1
			if (this.data.passengers.youth <= 0) {
				success = false;
				errorOrigin = 'passengers';
				message = _defaults.errors.passengersNotSet;
			}

			// if not all child ages have been selected...
			if (this.data.passengers.youth.count > this.data.passengers.youth.ages.length) {
				success = false;
				errorOrigin = 'passengers';
				message = _defaults.errors.youthAgesNotSet;
			}
		} else {
			// check if adult passenger number is below 1
			if (this.data.passengers.adults <= 0) {
				success = false;
				errorOrigin = 'passengers';
				message = _defaults.errors.passengersNotSet;
			}

			// if not all child ages have been selected...
			if (this.data.passengers.child.count > this.data.passengers.child.ages.length) {
				success = false;
				errorOrigin = 'passengers';
				message = _defaults.errors.childAgesNotSet;
			}
		}

		// check if there are any legs to begin with
		if (!this.data.legs.length) {
			success = false;
			message = _defaults.errors.departNotSet;
		}

		// loop over legs and check if any destinations are empty...
		this.data.legs.forEach((leg) => {
			const emptyFrom = !leg.fromIatas.length;
			const emptyTo = !leg.toIatas.length;
			let departIsReturn = false;

			for (const a of leg.fromIatas) {
				for (const b of leg.toIatas) {
					if (a.iata === b.iata && a.isMetro === b.isMetro) {
						departIsReturn = true;
					}
				}
			}

			if (emptyFrom) {
				success = false;
				message = _defaults.errors.departNotSet;
			} else if (emptyTo) {
				success = false;
				message = _defaults.errors.returnNotSet;
			} else if (departIsReturn) {
				success = false;
				message = _defaults.errors.departIsReturn;
			}
		});
		// some options are always disabled for multi-city searches -
		// but we want to be able to keep those boxes selected if just switching between single/multi modes
		if (this.data.tripType === 2) /* multi */ {
			this.data.nearbyDates = 0;
			this.data.direct = 0;

			if (!this.validateMultiCityDates()) {
				success = false;
				message = _defaults.errors.departDateIsAfterReturn;
			}
		}

		return { success, errorOrigin, message };
	}

	saveSearchToLocalStorage () {
		if (!this.data || this.data === {}) {
			console.log('Tried to save empty user search');
			return;
		}
		storedItemCreate('userSavedSearch', this.data);
	}

	formatDataForMultiSearch () {
		const toFormattedIata = ci => ({ iata: ci.iata, isMetro: ci.isMetro });
		const trips = this.data.legs.map(leg => ({
			from: leg.fromIatas.map(toFormattedIata),
			to: leg.toIatas.map(toFormattedIata),
			date: leg.departDate
		}));

		if (this.data.tripType === 1) /* return */ {
			const firstLeg = this.data.legs[0];
			trips.push({
				// We need to flip from and to
				from: firstLeg.toIatas.map(toFormattedIata),
				to: firstLeg.fromIatas.map(toFormattedIata),
				date: firstLeg.returnDate
			});
		}

		return trips;
	}

	formatDataForSuperSpecialAPI () {
		// We really ought to change the way the request handles data.
		// This is super ugly and pointless :(

		const legs = this.data.legs;
		const trips = [];
		let leg1 = {};
		const leg2 = {};

		if (this.data.tripType === 1) /* return */ {
			legs.forEach((leg, index) => {
				leg1.from = leg.fromIata;
				leg1.to = leg.toIata;
				leg1.fromMetro = leg.fromMetro;
				leg1.toMetro = leg.toMetro;
				leg1.date = leg.departDate;

				leg2.from = leg.toIata;
				leg2.to = leg.fromIata;
				leg2.fromMetro = leg.toMetro;
				leg2.toMetro = leg.fromMetro;
				leg2.date = leg.returnDate;

				trips.push(leg1, leg2);
			});
		} else {
			legs.forEach((leg, index) => {
				leg1 = {};

				leg1.from = leg.fromIata;
				leg1.to = leg.toIata;
				leg1.fromMetro = leg.fromMetro;
				leg1.toMetro = leg.toMetro;
				leg1.date = leg.departDate;

				trips.push(leg1);
			});
		}

		return trips;
	}

	submit () {
		if (this.isSearching) {
			return;
		}

		this.updateLegs();

		const isValidData = this.validateData();

		if (isValidData.success === true) {
			this.showLoader();
			const formattedTrips = this.formatDataForMultiSearch();

			this.isSearching = true;
			this.resultCount = 0;

			const postData = {
				trips: formattedTrips,
				returntrip: this.data.tripType,
				classType: this.data.cabin,
				passengers: this.data.passengers
			};

			this.initiateSearch([postData]);
		} else {
			// data not valid! Show friendly error...
			this.hideLoader();
			this.isSearching = false;

			if (isValidData.errorOrigin === 'passengers') {
				this.focusPassengersField();
			} else {
				window.alert(isValidData.message);
			}
		}
	}

	initiateSearch (postData) {
		if (postData[0]) {
			window.aboutToSearch = 1;
			logEvent(events.search, eventCategories.search, JSON.stringify(postData[0]));
		}

		const handleError = () => {
			console.log('there was an error, dumping response args');
			// TODO: arguments is a reserved word in JS...
			console.log(arguments);

			this.cancelSearch();
			this.hideLoader();

			if (this._callbacks.onSearchError) {
				this._callbacks.onSearchError(this);
			}
			this.handleErrorMessage({ error: l('error.e9') });
		};

		if ('force_server' in js_params.urlVars) {
			postData[0].server = js_params.urlVars.force_server;
		}

		this.request = ConnectApi('Search', 'initiateSearch', postData).then((resp) => {
			const response = resp.results;
			const errors = resp.error;
			const { nearbyDates, direct, baggage, cabinBaggage, climateComp, flexTicket, tripType } = this.data;

			if (errors) {
				document.body.classList.remove('search-loading');
				this.html.classList.remove('abSearchBox--loading');

				if (this.showresultMode) {
					document.querySelector('.abShowResultSearchBox__inner').classList.remove('full-view');
				}

				errors.forEach(error => window.alert(error));

				this.cancelSearch();

				return;
			}

			if (response.id) {
				const { masterid, server } = response;
				const filterParams = {};
				let targetUrl = `/${lLink('global.navigation.resultsLink')}?id=${response.id}`;

				if (direct === 1) {
					filterParams.numStops = 0;
				}

				if (baggage === 1) {
					filterParams.baggageIncluded = 1;
				}

				if (cabinBaggage === 1) {
					filterParams.cabinBaggageIncluded = 1;
				}

				if (climateComp === 1) {
					filterParams.climateCompensated = 1;
				}

				if (flexTicket === 1) {
					filterParams.flexTicket = 1;
				}

				if (Object.keys(filterParams).length) {
					const params = Base64.encode(JSON.stringify(filterParams));

					targetUrl += `&filter=${params}`;
				}

				if (this.data.nearbyDates) {
					targetUrl += `&nearbyDates=${this.data.nearbyDates}`;
				}

				this.saveSearchToLocalStorage();
				this.showresultURL = targetUrl;

				setTimeout(() => {
					this.continueSearch(
						{
							exportid: masterid,
							server: server
						});
				}, 3500);
			}
		});
	}

	continueSearch (requestInformation) {
		return new Promise(async (resolve, reject) => {
			const reference = [
				{
					masterid: requestInformation.exportid,
					server: requestInformation.server
				}
			];

			const handleError = (error = '') => {
				if (error === '') {
					error = { error: l('error.e9') };
				} else if (this.showresultMode && (parseInt(_defaults.userSavedSearch?.nearbyDates) === 1)) {
					error = { error: l('error.e11') };
				}
				this.cancelSearch();
				this.hideLoader();

				if (this._callbacks.onSearchError) {
					this._callbacks.onSearchError(this);
				}

				if (this.searchProgressBox) {
					this.searchProgressBox.close();
				}

				this.handleErrorMessage(error);
			};

			this.isSearching = true;

			if (typeof this.searchProgressBox === 'undefined') {
				if (this.showresultMode) {
					this.searchProgressBox = new SearchProgressBox();
				}
			}

			this.request = ConnectApi('Search', 'update', reference).then(async (resp) => {
				// duck tape fix
				if (resp === null) {
					if (this.nullCount < 1) {
						this.nullCount += 1;
						await sleep(1000);
						await this.continueSearch(requestInformation);
					} else {
						this.nullCount = 0;
						handleError();
						return false;
					}
				} else if (resp.error) {
					if (this.data.nearbyDates) {
						this.data.forceNearbyDates = true;
						await this._callbacks.onSearchComplete(this);
						this.isSearching = false;

						if (this.searchProgressBox) {
							this.searchProgressBox.close();
						}
					} else {
						handleError(resp);
					}
					return false;
				} else if (resp.results.status === 'searching') {
					if (resp.previewgroup) {
						if (this._callbacks.onPreviewReceived) {
							this._callbacks.onPreviewReceived(this, resp.previewgroup);
						}

						if (this.showresultMode && this.searchProgressBox) {
							this.searchProgressBox.setNumberOfFoundResults(resp.results.totalRows);
						}

						this.resultCount = resp.results.totalRows;
					}

					if (this.isSearching) {
						await sleep(1000);
						await this.continueSearch(requestInformation);
					}
				} else if (resp.results.status === 'complete') {
					if (this._callbacks.onSearchComplete) {
						await this._callbacks.onSearchComplete(this);
					}

					this.isSearching = false;

					if (this.searchProgressBox) {
						this.searchProgressBox.close();
					}
				}
			}).catch(handleError);
			await this.request;
			resolve();
		});
	}

	cancelSearch () {
		this.isSearching = false;

		if (this.request) {
			this.request.abort();
		}
	}

	showErrorModal (error) {
		error.error = error.error_code === 'E8' ? l('searchbox.error.noResultsWithSelectedDates') : error.error;
		const states = [
			{
				buttons: [
					{
						id: 'closeModal',
						html: `<button id="closeModal" class="abBtn abBtn--whiteBlue abBtn--flex"><span>${l('searchbox.error.noResultsWithSelectedDates.cancel')}</span></button>`,
						callback: () => {
							this.errorModal.close();
						}
					},
					{
						id: 'forceNeabyDates',
						html: `<button id="forceNeabyDates" class="abBtn--blueWhite abBtn abBtn--flex"><span>${l('searchbox.error.noResultsWithSelectedDates.confirm')}</span></button>`,
						callback: () => {
							window.location = this.showresultURL + '&forceNearbyDates=1';
						}
					}
				]
			},
			{
				buttons: [
					{
						id: 'closeModal',
						html: `<button id="closeModal" class="abBtn--blueWhite abBtn abBtn--flex">${l('searchBox.search.searchBtnText') }</button>`,
						callback: () => {
							this.errorModal.close();
						}
					}
				]
			}

		];
		const ctaButton = error?.error_code === 'E8' ? states[0] : states[1];
		this.errorModal = new Modal({
			id: 'searchErrorModal',
			className: 'modal--searchError',
			hideFooter: false,
			hideOnSwipe: true,
			resetScroll: true,
			header: `<div class="title">${l('global.simplePhrases.oops')}</div><span class="icon-circle-cross"></span>`,
			footer: ctaButton.buttons.map(button => button.html).join(''),
			body: `
<div class="abDisplayMessage abDisplayMessage--column abDisplayMessage--withImageBg" style="display: flex;">
	<div class="abDisplayMessage__msg"><span>${error.error}</span></div>
</div>
`,
			onOpen: () => {
				this.errorModal.html.addEventListener('click', (event) => {
					if (event.target.classList.contains('icon-circle-cross')) {
						this.errorModal.close();
					} else if (event?.target?.nodeName === 'BUTTON') {
						ctaButton.buttons.find(button => button.id === event.target.id).callback();
					}
				});
			},
			onClose: () => {
				this.errorModal.html.removeEventListener('click', ctaButton.callback);
			}
		});
		this.errorModal.open();
	}


	handleErrorMessage (error) {
		if (this.showresultMode) {
			EventDispatcher.dispatch('searchBox.searchError', error);
		}
		this.showErrorModal(error);
	}

	validateReturnDates () {
		if (!this.data.legs.length) {
			return;
		}

		const departDate = this.data.legs[0].departDate;
		let returnDate = this.data.legs[0].returnDate;
		const returnField = this.html.getElementsByClassName('abSearchBox__return')[0];

		if (this.data.tripType === 1) {
			const returnIsBeforeDeparture = dateIsBefore(new Date(returnDate), new Date(departDate));
			if (!returnDate || returnIsBeforeDeparture) {
				returnDate = add14Days(departDate);
			}

			this.setDateField(returnField, returnDate);
		}
	}

	validateMultiCityDates () {
		let valid = true;

		const legs = this.data.legs.map(leg => new Date(leg.departDate));

		for (let i = 1; i < legs.length; i++) {
			const leg = legs[i];
			const prevDate = legs[i - 1];
			if (!dateIsAfter(leg, prevDate) && !dateIsSameDay(leg, prevDate)) {
				valid = false;
				break;
			}
		}

		return valid;
	}

	setDateSelectorView () {
		const wrapper = this.html.getElementsByClassName('abSearchBox__row--top')[0];
		const dateSelector = this.dateSelector.html;
		const styles = [
			'abDateSelector--oneway',
			'abDateSelector--return',
			'abDateSelector--multi'
		];

		if (!wrapper.contains(dateSelector)) {
			wrapper.appendChild(this.dateSelector.html);
		}

		for (let i = 0; i < styles.length; i++) {
			dateSelector.classList.remove(styles[i]);
		}

		dateSelector.classList.add(styles[this.data.tripType]);
	}

	refreshDatePickers (field) {
		const leg = field.closest('.abSearchBox__leg');
		const params = {
			elems: {},
			dates: {},
			tripType: this.data.tripType
		};

		params.elems.departField = leg.getElementsByClassName('abSearchBox__depart')[0];

		// If data('date') is falsy (likely a multi-city generated date field) then set depart date to today.
		params.dates.departDate = params.elems.departField.dataset.date || dateFormatYMD(new Date());

		if (params.tripType === 1) {
			params.elems.returnField = leg.getElementsByClassName('abSearchBox__return')[0];
			params.dates.returnDate = params.elems.returnField.dataset.date;
		}

		this.datePickers.render(params);

		let legNb = 0;

		// Calendar vertical positioning for multi view from tablet to bigger size
		if (params.tripType === 2) {
			legNb = parseInt(leg.getElementsByClassName('abSearchBox__circle--number')[0].innerHTML);
		}

		(legNb !== 0) ? this.dateSelector.show(legNb) : this.dateSelector.show();
	}

	setFixedStyles () {
		const quickFixDepart = this.html.querySelector('.abSearchBox__depart');
		const quickFixReturn = this.html.querySelector('.abSearchBox__return');
		const quickFixApply = this.html.querySelector('.abSearchBox__date-apply');
		const quickFix = window.innerHeight - 60 + 'px';

		quickFixDepart.style.top = quickFix;
		quickFixReturn.style.top = quickFix;
		quickFixApply.style.top = quickFix;
	}

	removeFixedStyles () {
		const quickFixDepart = this.html.querySelector('.abSearchBox__depart');
		const quickFixReturn = this.html.querySelector('.abSearchBox__return');
		const quickFixApply = this.html.querySelector('.abSearchBox__date-apply');

		quickFixDepart.style.top = '';
		quickFixReturn.style.top = '';
		quickFixApply.style.top = '';
	}

	enableShowresultMode () {
		this.showresultMode = true;

		const nav = this.html.getElementsByClassName('abSearchBox__nav')[0];

		nav.classList.add('abTabNav--transparent');
	}

	updateDataDates (departDate, returnDate) {
		if (this.data.legs.length !== 1) {
			return;
		}

		if (departDate) {
			this.data.legs[0].departDate = departDate;
		}

		if (returnDate) {
			this.data.legs[0].returnDate = returnDate;
		}

		return this;
	}

	setFieldsMaxIatas (limit = null) {
		for (const { from, to } of this.searchFields) {
			from.setMaxIatas(limit);
			to.setMaxIatas(limit);
		}
	}

	sortPassengersAges () {
		const passengers = this.data.passengers;
		const agesToSort = ('youth' in passengers) ? passengers.youth.ages : passengers.child.ages;
		agesToSort.sort((a, b) => a - b);
	}

	setPassengers () {
		const { adults, child } = (this.selectedPassengersView === 'default') ? this.data.passengers : this.passengersViewsData.default;
		let youth;

		this.passengersSetGroup({ type: 'adult', value: adults });
		this.passengersSetGroup({ type: 'child', value: child.count });

		if (this.isYouthTicketEnabled) {
			youth = (this.selectedPassengersView === 'youth') ? this.data.passengers.youth : this.passengersViewsData.youth.youth;
			this.passengersSetGroup({ type: 'youth', value: youth.count });
		}
		this.passengersHideAgesGroup();

		if (child.count > 0 || (this.isYouthTicketEnabled && youth.count > 0)) {
			this.passengersEnableApplyBtn();
			this.passengersDeactivateAges();
			this.passengersDeactivateRanges();
			this.passengersHideAges();

			let ageGroup;

			if (child.count > 0) {
				ageGroup = this.passengersGetAgeGroupByType('child');

				for (let i = 0; i < child.ages.length; i++) {
					this.passengersSetAgeBlock(ageGroup, i, parseInt(child.ages[i]));
				}

				this.passengersShowAgesGroup('child');

				if (this.isYouthTicketEnabled && youth.count === 0) {
					const youthAgeGroup = this.passengersGetAgeGroupByType('youth');
					this.passengersHideAges(youthAgeGroup);
					this.passengersHideAgesGroup(youthAgeGroup);
					this.passengersResetAgeBlocks(youthAgeGroup);
				}
			}

			if (this.isYouthTicketEnabled && youth.count > 0) {
				ageGroup = this.passengersGetAgeGroupByType('youth');
				const hasAllAges = youth.ages.length === youth.count;
				for (let i = 0; i < youth.count; i++) {
					const age = (hasAllAges) ? youth.ages[i] : false;
					this.passengersSetAgeBlock(ageGroup, i, age);
				}
				this.passengersShowAgesGroup('youth');

				if (child.count === 0) {
					const childAgeGroup = this.passengersGetAgeGroupByType('child');
					this.passengersHideAges(childAgeGroup);
					this.passengersHideAgesGroup(childAgeGroup);
					this.passengersResetAgeBlocks(childAgeGroup);
				}
			}
		} else {
			this.passengersEnableApplyBtn();
			this.passengersHideAges();
			this.passengersHideAgesGroup();
			this.passengersResetAgeBlocks();
		}

		this.passengersHideAgesRange();

		this.setPassengersView(this.selectedPassengersView);

		this.passengersUpdateField();
	}

	passengersSetGroup (config) {
		const { type, value } = config;
		const passengersMaxAgeMsg = this.elems.passengersMaxAgeMsg;

		const disabled = 'abPassengers__btn--disabled';
		const group = this.passengersGetGroupByType(type);

		const passengersAgeGroup = this.passengersGetAgeGroupByType(type);
		const countInput = group.getElementsByClassName('abPassengers__count')[0];
		const btnMin = group.getElementsByTagName('button')[0];
		const btnAdd = group.getElementsByTagName('button')[1];

		countInput.innerHTML = value;
		group.dataset.passengercount = value;
		const passengerCount = qsa('.abPassengers__count').reduce((accumulator, passengersCountElement) => accumulator + parseInt(passengersCountElement.innerHTML), 0);

		if (type === 'adult') {
			if (value === 1) {
				btnMin.classList.add(disabled);
			}

			if (value > 1 && value <= _defaults.maxPassengers) {
				btnMin.classList.remove(disabled);
			}
		} else {
			if (value === 0) {
				btnMin.classList.add(disabled);
				btnAdd.classList.remove(disabled);
			}

			if (value > 0 && value <= _defaults.maxPassengers) {
				btnMin.classList.remove(disabled);
				btnAdd.classList.remove(disabled);
			}

			// For tablets and big screens style
			if (value > 2) {
				passengersAgeGroup.classList.add('full-line');
			} else {
				passengersAgeGroup.classList.remove('full-line');
			}
		}

		if (passengerCount < _defaults.maxPassengers) {
			passengersMaxAgeMsg.classList.add('hidden');
			qsa('[data-action="add-passenger"]').forEach(element => element.classList.remove(disabled));
		} else {
			passengersMaxAgeMsg.classList.remove('hidden');
			qsa('[data-action="add-passenger"]').forEach(element => element.classList.add(disabled));
		}
	}

	passengersGetSelectedAges (type) {
		const ageGroup = this.passengersGetAgeGroupByType(type);
		const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);

		const selectedAges = [];

		for (let i = 0; i < ageBlocks.length; i++) {
			const block = ageBlocks[i];
			const isVisible = block.classList.contains('abPassengers__age--visible');

			if (isVisible) {
				selectedAges.push(parseInt(block.dataset.age, 10));
			}
		}

		return selectedAges;
	}

	passengersAreAtLeastOneSelected () {
		let isAtLeastOneSelected = false;
		this.passengerTypes.forEach((type) => {
			const count = this.passengersGetGroupByType(type).dataset.passengercount;
			if (type === 'adult' && count === 0) {
				return false;
			} else {
				isAtLeastOneSelected = isAtLeastOneSelected || (count > 0);
			}
		});
		return isAtLeastOneSelected;
	}

	passengersAreAllAgesSelected (ageGroup = null) {
		const ageBlocks = ageGroup ? this.passengersGetAgeBlocksByGroup(ageGroup) : this.passengersGetAllAgeBlocksByView(this.activePassengersView);
		let type = ageBlocks[0].dataset.type;
		let passengerCount = this.passengersGetGroupByType(type).dataset.passengercount;

		for (let i = 0; i < ageBlocks.length; i++) {
			const block = ageBlocks[i];
			const blockType = block.dataset.type;
			if (blockType !== type) {
				type = blockType;
				passengerCount = this.passengersGetGroupByType(type).dataset.passengercount;
			}
			if (block.dataset.passenger <= passengerCount && block.dataset.age === 'false') {
				return false;
			}
		}
		return true;
	}

	passengersApplyChanges () {
		const currentView = this.activePassengersView;
		if (currentView !== this.selectedPassengersView && this.availablePassengersViews.includes(currentView)) {
			this.selectedPassengersView = currentView;
			this.data.passengers = Object.assign({}, _defaults.passengersViewsData[currentView]);
		}

		const logPassengers = [];

		this.passengerTypes.forEach((type) => {
			const passenger = this.passengersGetGroupByType(type);
			const passengerCount = parseInt(passenger.dataset.passengercount, 10);

			logPassengers.push({ name: type, count: passengerCount });

			if (type === 'adult') {
				this.data.passengers.adults = passengerCount;
			} else {
				this.data.passengers[type].count = passengerCount;
				if (passengerCount >= 1) {
					const ages = this.passengersGetSelectedAges(type);
					this.data.passengers[type].ages = ages;
				} else {
					this.data.passengers[type].ages = [];
				}
			}
		});

		logEvent(events.editsearchPassengers, eventCategories.search, JSON.stringify(logPassengers));

		this.passengersUpdateField();
	}

	passengersBtnClicked (type, action) {
		const group = this.passengersGetGroupByType(type);

		let value = parseInt(group.dataset.passengercount, 10);

		value = (action === 'add') ? ++value : --value;

		this.passengersSetGroup({ type, value });

		if (type === 'adult') {
			if (action === 'add') {
				if (this.passengersAreAllAgesSelected()) {
					this.passengersEnableApplyBtn();
				}
			} else if (action === 'remove') {
				if (value === 0) {
					this.passengersDisableApplyBtn();
				}
			}
		} else {
			const ageGroup = this.passengersGetAgeGroupByType(type);
			if (action === 'add') {
				this.passengersDisableApplyBtn();
				this.passengersDeactivateAges();
				this.passengersDeactivateRanges();
				this.passengersHideAgesRange();
				this.passengersShowAges(group, ageGroup);
				this.passengersShowAgesGroup(type);
				this.passengersFocusLastAgeBlock(ageGroup);
			} else if (action === 'remove') {
				if (value === 0) {
					this.passengersHideAgesGroup(ageGroup);
					this.passengersHideAgesRange();
				}

				if (this.passengersAreAllAgesSelected(ageGroup)) {
					this.passengersDeactivateAges();
					this.passengersHideAgesRange();
					this.passengersToggleApplyBtn();
				} else {
					this.passengersDeactivateAges(ageGroup);
					this.passengersFocusLastAgeBlock(ageGroup);
				}

				this.passengersResetLastAgeBlock(ageGroup);
			}
		}
	}

	passengersFocusAgeRange (ageRanges, age) {
		for (let i = 0; i < ageRanges.length; i++) {
			const ageRange = ageRanges[i];

			if (ageRange.dataset.age === age) {
				ageRange.classList.add('abPassengers__range--active');
			} else {
				ageRange.classList.remove('abPassengers__range--active');
			}
		}
	}

	passengersShowAges (group, ageGroup) {
		const passengerCount = parseInt(group.dataset.passengercount, 10);
		const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);

		for (let i = 0; i < ageBlocks.length; i++) {
			const ageBlock = ageBlocks[i];

			if (i < passengerCount) {
				ageBlock.classList.add('abPassengers__age--visible');
			} else {
				ageBlock.classList.remove('abPassengers__age--visible');
			}
		}
	}

	passengersSetAgeBlock (ageGroup, index, age = false) {
		const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);
		const ageBlock = ageBlocks[index];
		const span = ageBlock.getElementsByTagName('span')[1];
		const textYearsOld = age + ' ' + l('searchBox.passengers.yearsOld');
		const textMonthsOld = ' 0-23 ' + l('searchBox.passengers.months');
		const text = (age === 0) ? textMonthsOld : textYearsOld;

		ageBlock.dataset.age = age;
		span.innerHTML = (age === false) ? l('searchBox.passengers.selectAge') : text;

		ageBlock.classList.add('abPassengers__age--visible');
	}

	passengersHideAges (ageGroup = null) {
		const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);

		for (let i = 0; i < ageBlocks.length; i++) {
			ageBlocks[i].classList.remove('abPassengers__age--visible');
		}
	}

	passengersDeactivateAges (ageGroup = null) {
		const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);

		for (let i = 0; i < ageBlocks.length; i++) {
			ageBlocks[i].classList.remove('abPassengers__age--active');
		}
	}

	passengersGetGroupByType (type) {
		const group = this.elems.passengers.getElementsByClassName(`abPassengers__group--${type}`)[0];
		return group;
	}

	passengersGetAgeGroupByType (type) {
		const ageGroup = this.elems.passengers.getElementsByClassName(`abPassengers__group--ages ${type}`)[0];
		return ageGroup;
	}

	passengersGetAgeBlocksByGroup (ageGroup = null) {
		if (!ageGroup) {
			return this.elems.passengersAllAgeBlocks;
		}

		const blocks = ageGroup.getElementsByClassName('abPassengers__age');
		return blocks;
	}

	passengersGetAllAgeBlocksByView (view) {
		const blocks = this.html.querySelector(`.abPassengers__view--${view}`).getElementsByClassName('abPassengers__age');
		return blocks;
	}

	passengersGetAgeRangeByGroup (ageGroup = null) {
		if (!ageGroup) {
			return this.elems.passengersAllAgeRange;
		}

		const range = ageGroup.getElementsByClassName('abPassengers__ranges')[0];
		return range;
	}

	passengersGetAgeRangesByGroup (ageGroup = null) {
		if (!ageGroup) {
			return this.elems.passengersAllAgeRanges;
		}

		const ranges = ageGroup.getElementsByClassName('abPassengers__range');
		return ranges;
	}

	passengerFocusAgeBlock (ageGroup) {
		const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);
		this.passengersDeactivateRanges();

		for (let i = 0; i < ageBlocks.length; i++) {
			const age = ageBlocks[i].dataset.age;

			if (age === 'false') {
				ageBlocks[i].click();

				break;
			}
		}
	}

	passengersFocusActiveAgeBlock (ageGroup) {
		this.passengerFocusAgeBlock(ageGroup);
		if (isMobileView()) {
			this.passengersScrollToActiveAgeBlock(ageGroup);
		}
	}

	passengersFocusLastAgeBlock (ageGroup) {
		this.passengerFocusAgeBlock(ageGroup);
		if (isMobileView()) {
			this.passengersScrollToLastAgeBlock(ageGroup);
		}
	}

	passengersResetAgeBlock (ageBlock) {
		const span = ageBlock.getElementsByTagName('span')[1];

		span.innerHTML = l('searchBox.passengers.selectAge');

		ageBlock.dataset.age = 'false';
		ageBlock.classList.remove('abPassengers__age--active');
		ageBlock.classList.remove('abPassengers__age--visible');
	}

	passengersResetAgeBlocks (ageGroup = null) {
		const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);

		for (let i = ageBlocks.length - 1; i >= 0; i--) {
			this.passengersResetAgeBlock(ageBlocks[i]);
		}
	}

	passengersResetLastAgeBlock (ageGroup) {
		const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);

		for (let i = ageBlocks.length - 1; i >= 0; i--) {
			const ageBlock = ageBlocks[i];

			if (ageBlock.classList.contains('abPassengers__age--visible')) {
				this.passengersResetAgeBlock(ageBlock);

				break;
			}
		}
	}

	passengersDeactivateRanges () {
		const ranges = this.elems.passengersAllAgeRanges;

		for (let i = 0; i < ranges.length; i++) {
			ranges[i].classList.remove('abPassengers__range--active');
		}
	}

	passengersShowAgesGroup (type) {
		const passengersAgeGroup = this.elems.passengers.getElementsByClassName(`abPassengers__group--ages ${type}`)[0];

		if (!passengersAgeGroup.classList.contains('visible')) {
			slideDown(passengersAgeGroup);
		}
		passengersAgeGroup.classList.add('visible');
		this.elems.passengers.classList.add('abSearchBox__passengers--showAges');
	}

	passengersHideAgesGroup (ageGroup = null) {
		const ageGroups = this.elems.passengersAllAgeGroup;
		let hasAgeGroupVisible = false;
		if (ageGroup) {
			slideUp(ageGroup);
			ageGroup.classList.remove('visible');

			hasAgeGroupVisible = this.elems.passengers.querySelectorAll('.abPassengers__group--ages.visible').length > 0;
		} else {
			for (let i = 0; i < ageGroups.length; i++) {
				if (ageGroups[i].classList.contains('visible')) {
					slideReset(ageGroups[i]);
				}
				ageGroups[i].classList.remove('visible');
				ageGroups[i].style.display = '';
			}
		}

		if (!hasAgeGroupVisible) {
			this.elems.passengers.classList.remove('abSearchBox__passengers--showAges');
		}
	}

	passengersShowAgesRange (type, passenger) {
		const passengersAgeGroup = this.passengersGetAgeGroupByType(type);
		const passengersAgeRange = this.passengersGetAgeRangeByGroup(passengersAgeGroup);

		for (let i = 1; i < 4; i++) {
			passengersAgeGroup.classList.remove('line-' + i);
		}

		if (passenger <= 3) {
			passengersAgeGroup.classList.add('line-1');
		}

		if (passenger > 3 && passenger <= 6) {
			passengersAgeGroup.classList.add('line-2');
		}

		if (passenger > 6) {
			passengersAgeGroup.classList.add('line-3');
		}

		passengersAgeRange.style.display = '';
		passengersAgeGroup.classList.add('opened-range');
	}

	passengersHideAgesRange () {
		const allRanges = this.elems.passengersAllAgeRange;
		for (let i = 0; i < allRanges.length; i++) {
			allRanges[i].style.display = 'none';
		}

		const allGroups = this.elems.passengersAllAgeGroup;
		for (let j = 0; j < allGroups.length; j++) {
			allGroups[j].classList.remove('opened-range');
		}
	}

	passengersDisableApplyBtn () {
		this.elems.passengersApplyBtn.disabled = true;
	}

	passengersToggleApplyBtn () {
		if (this.passengersAreAllAgesSelected() && this.passengersAreAtLeastOneSelected()) {
			this.passengersEnableApplyBtn();
			return true;
		} else {
			this.passengersDisableApplyBtn();
			return false;
		}
	}

	pointToIncompleteAgeBlock () {
		const type = (this.activePassengersView === 'default') ? 'child' : 'youth';
		const count = this.passengersGetGroupByType(type).dataset.passengercount;
		if (count > 0) {
			this.passengersFocusLastAgeBlock(this.passengersGetAgeGroupByType(type));
		}
	}

	passengersEnableApplyBtn () {
		this.elems.passengersApplyBtn.disabled = false;
	}

	passengersScrollToActiveAgeBlock (ageGroup) {
		const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);
		const firstAgeBlockOffset = ageBlocks[0].offsetLeft;
		const activeAgeBlockOffset = ageGroup.getElementsByClassName('abPassengers__age--active')[0].offsetLeft;

		const scrollLeft = activeAgeBlockOffset - firstAgeBlockOffset;
		const ageBlock = ageGroup.getElementsByClassName('abPassengers__ages')[0];

		ageBlock.scrollTo({ top: scrollLeft, behavior: 'smooth' });
	}

	passengersScrollToLastAgeBlock (ageGroup) {
		const ageBlock = ageGroup.querySelector('.abPassengers__ages');
		const scrollWidth = ageGroup.scrollWidth;
		ageBlock.scrollTo({ left: scrollWidth, behavior: 'smooth' });
	}

	passengersGetScrollerOffsets (scroller) {
		const scrollerWidth = scroller.offsetWidth;
		const scrollerOffset = scrollerWidth / 2;
		const paddingOffset = isMobileView() ? parseInt(getComputedStyle(scroller).paddingRight, 10) * 2 : 0;
		const positionLeft = isDesktopView() ? scrollerWidth : 0;

		return scrollerOffset + paddingOffset + positionLeft;
	}

	passengersScrollToSelectedAgeRange (ageGroup) {
		const ranges = this.passengersGetAgeRangesByGroup(ageGroup);
		const scroller = this.passengersGetAgeRangeByGroup(ageGroup).children[0];
		scroller.scrollLeft = 0;

		for (let i = 0; i < ranges.length; i++) {
			const age = ranges[i];

			if (age.classList.contains('abPassengers__range--active')) {
				const left = age.getBoundingClientRect().left;
				const width = parseInt(getComputedStyle(age).width, 10);
				const scrollLeft = left - this.passengersGetScrollerOffsets(scroller) + width / 2;

				scroller.scrollTo({ left: scrollLeft, behavior: 'smooth' });
				break;
			}
		}
	}

	passengersScrollToMiddleAgeRange (ageGroup) {
		const scroller = this.passengersGetAgeRangeByGroup(ageGroup).children[0];
		scroller.scrollLeft = 0;

		const age = ageGroup.querySelector('.abPassengers__range[data-median="1"]');
		const left = age.getBoundingClientRect().left;
		const width = parseInt(getComputedStyle(age).width, 10);
		const scrollLeft = left - this.passengersGetScrollerOffsets(scroller) + width / 2;

		scroller.scrollTo({ left: scrollLeft, behavior: 'smooth' });
	}

	passengersScrollToMedianAgeRange (ageGroup) {
		const scroller = this.passengersGetAgeRangeByGroup(ageGroup).children[0];
		scroller.scrollLeft = 0;

		const age = ageGroup.querySelector('.abPassengers__range[data-median="1"]');
		const left = age.getBoundingClientRect().left;
		const width = parseInt(getComputedStyle(age).width, 10);
		const scrollLeft = left - this.passengersGetScrollerOffsets(scroller) + width / 2;

		scroller.scrollTo({ left: scrollLeft, behavior: 'smooth' });
	}

	setPassengersView (view) {
		// Hide current view
		this.html.querySelectorAll('.abPassengers__view').forEach(view => view.classList.add('hidden'));
		this.html.querySelectorAll('.abPassengers__view').forEach(view => view.classList.remove('selected'));
		// Show new view
		this.html.querySelector(`.abPassengers__view--${view}`).classList.remove('hidden');
		this.html.querySelector(`.abPassengers__view--${view}`).classList.add('selected');

		this.activePassengersView = view;
		this.passengerTypes = _defaults.passengerTypesPerView[view];
	}

	passengersUpdateField () {
		const adults = this.data.passengers.adults;
		const field = this.elems.passengers;
		const fieldValue = field.getElementsByClassName('abSearchBox__value')[0];

		const hasPassenger = passenger => (passenger.count > 0) ? true : false;
		const pluralText = passenger => this.passengerTypeTranslations[passenger].plural;
		const singleText = passenger => this.passengerTypeTranslations[passenger].singular;
		let inputText = '';
		let numberOfTypes = 0;

		this.passengerTypes.forEach((type) => {
			if (type === 'adult') {
				if (adults > 0) {
					inputText += `${adults} <small>${adults > 1 ? pluralText(type) : singleText(type)}</small>`;
					numberOfTypes++;
				}
			} else {
				const passenger = this.data.passengers[type];
				if (hasPassenger(passenger)) {
					inputText += `${inputText !== '' ? ', ' : ''}${passenger.count} <small>${passenger.count > 1 ? pluralText(type) : singleText(type)}</small>`;
					numberOfTypes++;
				}
			}
		});

		if (numberOfTypes > 1) {
			fieldValue.classList.add('abSearchBox__value--small');
		} else {
			fieldValue.classList.remove('abSearchBox__value--small');
		}

		fieldValue.innerHTML = inputText;
	}

	focusPassengersField (elem = null) {
		if (window.innerWidth >= 992) {
			this.blurFields();
			this.dateSelector.blurField();
		}
		if (!elem) {
			elem = this.html.querySelector('.abSearchBox__passengers');
		}
		const passengers = this.elems.passengers;
		const focused = this.focusField(elem, 'abSearchBox__row--focus-passengers');

		if (isMobileView() && focused) {
			this.scrollTopView(passengers, 40);
		}

		this.setPassengers();

		const canApply = this.passengersToggleApplyBtn();
		if (!canApply) {
			this.pointToIncompleteAgeBlock();
		}
	}

	setCheckboxes () {
		this.setDirectCheckbox();
		this.setBaggageCheckbox();
		this.setCabinBaggageCheckbox();
		this.setClimateCompCheckbox();
		this.setFlexTicketCheckbox();
		this.setNearbyCheckbox();
	}

	resetSearchBox () {
		this.data = deepCopy(this.initialData);
		this.syncPassengersTypesAndViewWithData();

		this.setView();
		this.setCheckboxes();
		this.setPassengers();
		this.setCabin();

		if (this.data.legs.length) {
			const legs = this.html.getElementsByClassName('abSearchBox__leg');
			this.data.legs.forEach((legData, index) => {
				const leg = legs[index];
				if (leg) {
					let field = leg.getElementsByClassName('abSearchBox__depart')[0];
					let date = legData.departDate || _defaults.dates.from;

					this.setupDateField({ field, date });

					if (this.data.tripType === 1) {
						field = leg.getElementsByClassName('abSearchBox__return')[0];
						date = legData.returnDate || _defaults.dates.to;

						this.setupDateField({ field, date });
					}
				} else {
					this.renderMultiCityLeg(index);
				}
			});

			this.setInitialCheckedIatas();

			if (this.data.tripType === 2) {
				const numberOfLegs = this.data.legs.length;
				this.renderMultiCitySummary();

				while (this.searchFields.length > numberOfLegs) {
					this.searchFields.splice(numberOfLegs, 1);
					const leg = this.html.getElementsByClassName('abSearchBox__leg')[numberOfLegs];
					if (leg) {
						leg.parentNode.removeChild(leg);
					}
				}

				const addBtn = this.html.getElementsByClassName('abSearchBox__btn--add')[0];
				const btn = this.html.getElementsByClassName('abSearchBox__add-leg')[0];

				if (numberOfLegs < this.maxNumberOfLegs) {
					addBtn.disabled = false;
					btn.classList.remove('disabled');
				} else {
					addBtn.disabled = true;
					btn.classList.add('disabled');
				}

				this.renderMultiCityRemoveBtns();
			}
		}
	}

	events () {
		document.addEventListener('click', (event) => {
			const elem = event.target;

			if (elem.matches('button')) {
				const action = elem.dataset.action;

				if (action === 'set-view') {
					const type = parseInt(elem.dataset.type, 10);

					if (window.innerWidth >= 992) {
						this.blurFields();
						this.dateSelector.blurField();
					}

					this.data.tripType = type;

					if (type === 1) /* return */ {
						this.validateReturnDates();
					}

					// Only log if it is a human event and not on arrival setup
					if (qs('.abSearchBox--extended .abSearchBox__view .abBtn--ticked')) {
						logEvent(events.editsearchTripType, eventCategories.search, this.data.tripType);
					}

					this.setView();
					this.updateLegs();
				} else if (action === 'switcher') {
					const leg = elem.closest('.abSearchBox__leg');
					const legIndex = parseInt(leg.querySelector('.abSearchBox__circle--number').innerText) - 1 || 0;
					const fromCheckedIatas = this.searchFields[legIndex].to.getCheckedIatas();
					const toCheckedIatas = this.searchFields[legIndex].from.getCheckedIatas();

					this.searchFields[legIndex].from.setCheckedIatas(fromCheckedIatas);
					this.searchFields[legIndex].to.setCheckedIatas(toCheckedIatas);

					elem.classList.toggle('abSearchBox__switcher--rotate');

					logEvent(events.editsearchSwitchDestination, eventCategories.search);

					this.updateLegs();
				} else if (action === 'search') {
					if (!isMobileView()) {
						const passengersFocused = this.html.querySelector('.abSearchBox__row--focus-passengers');
						if (passengersFocused) {
							const applyBtn = passengersFocused.querySelector('button.abPassengers__applyBtn');
							if (!applyBtn.disabled) {
								applyBtn.click();
							} else {
								return;
							}
						}
					}

					this.submit();
				} else if (action === 'add-leg') {
					const numOfLegs = this.html.getElementsByClassName('abSearchBox__leg').length;
					const btn = elem.closest('.abSearchBox__add-leg');

					if (numOfLegs < this.maxNumberOfLegs) {
						this.renderMultiCityLeg();

						elem.disabled = (numOfLegs === (this.maxNumberOfLegs - 1)) ? true : false;
						if (numOfLegs === (this.maxNumberOfLegs - 1)) {
							btn.classList.add('disabled');
						}
					} else {
						elem.disabled = false;
					}

					this.renderMultiCityRemoveBtns();
					this.setFieldsMaxIatas(1);
					EventDispatcher.dispatch('searchBox:legAdded');
				} else if (action === 'remove-leg') {
					const leg = elem.closest('.abSearchBox__leg');
					const btn = this.html.getElementsByClassName('abSearchBox__add-leg')[0];
					const addBtn = this.html.getElementsByClassName('abSearchBox__btn--add')[0];

					this.data.legs.splice(parseInt(leg.dataset.legIndex, 10), 1);
					this.searchFields.splice(parseInt(leg.dataset.legIndex, 10), 1);

					addBtn.disabled = false;

					btn.classList.remove('disabled');

					slideUp(leg);

					setTimeout(() => {
						leg.parentNode.removeChild(leg);
						this.renderMultiCityLegNumbers();
						this.renderMultiCityRemoveBtns();
						EventDispatcher.dispatch('searchBox:legRemoved', this.data.legs.length);
					}, 200);

					if (this.showresultMode) {
						this.renderMultiCitySummary();
					}
				} else if (action === 'apply-passengers') {
					event.stopPropagation();

					this.blurFields();
					this.passengersApplyChanges();
				} else if (action === 'apply-date') {
					this.blurFields();
					this.dateSelector.hide();
				} else if (action === 'set-cabin') {
					event.stopPropagation();

					const cabin = this.html.getElementsByClassName('abSearchBox__cabin')[0];
					const btns = cabin.getElementsByTagName('button');
					const input = cabin.getElementsByClassName('abSearchBox__value')[0];
					const value = elem.value;

					// Only log if it is a human event and not on arrival setup
					// It needs != because the value can be 0 and 0 !== 0 is true, because one is string and the other is number
					if (qs('.abSearchBox--extended .abSearchBox__cabin .abBtn--ticked') && this.data.cabin != value) {
						logEvent(events.editsearchTicketType, eventCategories.search, this.data.cabin);
					}

					this.data.cabin = parseInt(value, 10);
					this.blurFields();

					// Only log if it is a human event and not on arrival setup
					if (qs('.abSearchBox--extended .abSearchBox__cabin .abBtn--ticked')) {
						// This is adding in unfocus...
						logEvent(events.editsearchTicketType, eventCategories.search, this.data.cabin);
					}

					for (let i = 0; i < btns.length; i++) {
						btns[i].classList.remove('abBtn--ticked');
					}

					elem.classList.add('abBtn--ticked');
					input.innerHTML = elem.textContent;
				} else if (action === 'add-passenger') {
					if (!elem.classList.contains('abPassengers__btn--disabled')) {
						this.passengersBtnClicked(elem.dataset.type, 'add');
					}

					event.preventDefault();
				} else if (action === 'remove-passenger') {
					if (!elem.classList.contains('abPassengers__btn--disabled')) {
						this.passengersBtnClicked(elem.dataset.type, 'remove');
					}

					event.preventDefault();
				}

				return;
			}

			if (elem.matches('.abSearchBox__depart') || elem.closest('.abSearchBox__duration')) {
				this.dateSelector.setTitle(_defaults.dateSelector.departTitle);
				this.blurFields();

				if (window.innerWidth >= 992) {
					this.dateSelector.blurField();
				}

				this.focusField(elem, 'abSearchBox__row--focus-depart');

				this.datePickers.focus = 'depart';

				this.refreshDatePickers(elem);
				return;
			}

			if (!elem.matches('.SearchFieldMulti__field--from') && elem.closest('.SearchFieldMulti--from')) {
				if (window.innerWidth >= 992) {
					this.blurFields();
					this.dateSelector.blurField();
				}

				const field = elem.closest('.SearchFieldMulti--from');
				field.querySelector('input').focus();

				return;
			}

			if (elem.matches('.abSearchBox__return')) {
				this.dateSelector.setTitle(_defaults.dateSelector.returnTitle);
				this.blurFields();

				this.focusField(elem, 'abSearchBox__row--focus-return');

				this.datePickers.focus = 'return';

				this.refreshDatePickers(elem);
				return;
			}

			if (!elem.matches('.SearchFieldMulti__field--to') && elem.closest('.SearchFieldMulti--to')) {
				if (window.innerWidth >= 992) {
					this.blurFields();
					this.dateSelector.blurField();
				}

				const field = elem.closest('.SearchFieldMulti--to');
				field.querySelector('input').focus();

				return;
			}

			if (elem.matches('.abShowResultSearchBox__overlay')) {
				event.stopPropagation();
				const passengersFocused = this.html.querySelector('.abSearchBox__row--focus-passengers');
				if (!isMobileView() && passengersFocused) {
					const applyBtn = passengersFocused.querySelector('button.abPassengers__applyBtn');
					if (!applyBtn.disabled) {
						applyBtn.click();
						return;
					}
				}
			}

			if (elem.matches('.abSearchBox__overlay')) {
				event.stopPropagation();
				const passengersFocused = this.html.querySelector('.abSearchBox__row--focus-passengers');
				if (!isMobileView() && passengersFocused) {
					const applyBtn = passengersFocused.querySelector('button.abPassengers__applyBtn');
					if (!applyBtn.disabled) {
						applyBtn.click();
						return;
					}
				}

				if (window.innerWidth >= 992) {
					this.blurFields();
					this.dateSelector.blurField();
				}

				if (this.data.tripType === 1) {
					if (typeof this.datepickers !== 'undefined') {
						if (!this.datePickers.returnDate) {
							const { returnField, departDate } = this.datePickers;

							this.setDateField(returnField, departDate);
						}
					}
				}

				this.dateSelector.hide();

				return;
			}

			if (elem.matches('.abPassengers__range')) {
				event.stopPropagation();

				const selectedAge = parseInt(elem.dataset.age, 10);
				const type = elem.dataset.type;
				const ageGroup = this.passengersGetAgeGroupByType(type);
				const activeAgeBlock = ageGroup.getElementsByClassName('abPassengers__age--active')[0];
				const blockIndex = getNodeIndex(activeAgeBlock);

				this.passengersScrollToActiveAgeBlock(ageGroup);
				this.passengersDeactivateRanges();

				elem.classList.add('abPassengers__range--active');

				this.passengersSetAgeBlock(ageGroup, blockIndex, selectedAge);

				const $this = this;

				if ($this.passengersAreAllAgesSelected(ageGroup)) {
					$this.passengersHideAgesRange();
					$this.passengersDeactivateAges();
					if ($this.passengersAreAllAgesSelected() && $this.passengersAreAtLeastOneSelected()) {
						$this.passengersEnableApplyBtn();
					}
				} else {
					$this.passengersFocusActiveAgeBlock(ageGroup);
				}

				return;
			}

			if (elem.matches('.abPassengers__age')) {
				console.log('??');
				event.stopPropagation();

				const type = elem.dataset.type;
				const age = elem.dataset.age;
				const activePassenger = elem.dataset.passenger;
				const ageGroup = this.passengersGetAgeGroupByType(type);
				const ageBlocks = this.passengersGetAgeBlocksByGroup(ageGroup);

				for (let i = 0; i < ageBlocks.length; i++) {
					ageBlocks[i].classList.remove('abPassengers__age--active');
				}

				this.passengersDeactivateRanges();

				elem.classList.add('abPassengers__age--active');

				this.passengersShowAgesRange(type, activePassenger);

				if (age !== 'false') {
					const ageRanges = this.passengersGetAgeRangesByGroup(ageGroup);
					this.passengersFocusAgeRange(ageRanges, age);
					this.passengersScrollToSelectedAgeRange(ageGroup);
				} else {
					this.passengersScrollToMedianAgeRange(ageGroup);
				}

				return;
			}

			if (elem.matches('.abSearchBox__passengers')) {
				event.stopPropagation();
				this.focusPassengersField(elem);

				return;
			}

			if (elem.matches('.abSearchBox__cabin')) {
				if (window.innerWidth >= 992) {
					this.blurFields();
					this.dateSelector.blurField();
				}

				const cabin = this.html.getElementsByClassName('abSearchBox__cabin')[0];
				const focused = this.focusField(elem, 'abSearchBox__row--focus-cabin');

				if (isMobileView() && focused) {
					this.scrollTopView(cabin, 40);
				}

				return;
			}

			if (elem.matches('.abSearchBox__view')) {
				event.stopPropagation();

				if (window.innerWidth >= 992) {
					this.blurFields();
					this.dateSelector.blurField();
				}

				this.focusField(elem, 'abSearchBox__row--focus-view');

				return;
			}

			// Change passenger view
			if (elem.closest('[data-action="set-passenger-view"]')) {
				event.stopPropagation();
				if (!this.isYouthTicketEnabled) {
					return;
				}
				const newView = elem.closest('[data-action="set-passenger-view"]').dataset.view;

				this.passengersHideAgesRange();
				this.setPassengersView(newView);
				const canApply = this.passengersToggleApplyBtn();
				if (!canApply) {
					this.pointToIncompleteAgeBlock();
				}
				return;
			}

			if (elem.closest('.abSearchBox__checkbox--info')) {
				if (window.innerWidth >= 992) {
					this.blurFields();
					this.dateSelector.blurField();
				}

				event.stopPropagation();

				const toolTip = elem.closest('.abSearchBox__checkbox--info').getElementsByClassName('abTooltip')[0];

				document.querySelectorAll('div.abTooltip').forEach((tooltip) => {
					if (tooltip !== toolTip) {
						tooltip.classList.remove('abTooltip--visible');
					}
				});

				toolTip.classList.toggle('abTooltip--visible');

				return;
			}

			if (window.innerWidth >= 992) {
				if (elem.closest('.abSearchBox__menu')) {
					return;
				}

				if (
					elem.closest('.abSearchBox__checkboxes')
					|| !elem.closest('.abSearchBox')
					|| !elem.closest('.abDateSelector')
				) {
					const passengersFocused = this.html.querySelector('.abSearchBox__row--focus-passengers');

					if (!isMobileView() && passengersFocused) {
						const applyBtn = passengersFocused.querySelector('button.abPassengers__applyBtn');
						if (!applyBtn.disabled) {
							applyBtn.click();
							return;
						}
					}

					this.blurFields();
					this.dateSelector.blurField();
				}
			}
		}, false);

		EventDispatcher.addEventListener('submitSearch', () => this.submit());

		EventDispatcher.addEventListener('showSearchBoxYouthPassengers', () => {
			if (this.isYouthTicketEnabled) {
				this.focusPassengersField();
			}
		});

		EventDispatcher.addEventListener('searchBoxReset', () => this.resetSearchBox());

		document.addEventListener('change', (event) => {
			const elem = event.target;

			if (elem.matches('.abSearchBox__nearby')) {
				this.data.nearbyDates = +elem.checked;

				if (this.data.nearbyDates === 1) {
					logEvent(events.editsearchFlexibleDatesEnabled, eventCategories.search);
				} else {
					logEvent(events.editsearchFlexibleDatesDisabled, eventCategories.search);
				}
				return;
			}

			if (elem.matches('.abSearchBox__baggage')) {
				this.data.baggage = +elem.checked;

				if (this.data.baggage === 1) {
					logEvent(events.editsearchBaggageEnabled, eventCategories.search);
				} else {
					logEvent(events.editsearchBaggageDisabled, eventCategories.search);
				}

				return;
			}

			if (elem.matches('.abSearchBox__cabinBaggage')) {
				this.data.cabinBaggage = +elem.checked;

				if (this.data.cabinBaggage === 1) {
					logEvent(events.editsearchCabinBaggageEnabled, eventCategories.search);
				} else {
					logEvent(events.editsearchCabinBaggageDisabled, eventCategories.search);
				}
				return;
			}

			if (elem.matches('.checkbox-direct')) {
				this.data.direct = +elem.checked;

				if (this.data.direct === 1) {
					logEvent(events.editsearchDirectFlightOnlyEnabled, eventCategories.search);
				} else {
					logEvent(events.editsearchDirectFlightOnlyDisabled, eventCategories.search);
				}

				return;
			}

			if (elem.matches('.abSearchBox__cabinBaggage')) {
				this.data.cabinBaggage = +elem.checked;

				if (this.data.cabinBaggage === 1) {
					logEvent(events.editsearchCabinBaggageEnabled, eventCategories.search);
				} else {
					logEvent(events.editsearchCabinBaggageDisabled, eventCategories.search);
				}

				return;
			}

			if (elem.matches('.abSearchBox__climateComp')) {
				this.data.climateComp = +elem.checked;

				if (this.data.climateComp === 1) {
					logEvent(events.editsearchCO2CompensateEnabled, eventCategories.search);
				} else {
					logEvent(events.editsearchCO2CompensateDisabled, eventCategories.search);
				}

				return;
			}

			if (elem.matches('.abSearchBox__flexTicket')) {
				this.data.flexTicket = +elem.checked;

				if (this.data.flexTicket === 1) {
					logEvent(events.editsearchRebookableEnabled, eventCategories.search);
				} else {
					logEvent(events.editsearchRebookableDisabled, eventCategories.search);
				}
			}
		}, false);

		window.addEventListener('resize', (event) => {
			event.stopPropagation();

			if (this.showresultMode && this.data.tripType === 2) {
				this.renderMultiCitySummary();
			}
		}, false);

		// dateSelector events

		// TODO: temp solution to fixed elements on showresult page...
		if (this.showresultMode) {
			let resizeTimer;

			window.addEventListener('resize', (event) => {
				event.stopPropagation();

				if (resizeTimer) {
					cancelAnimationFrame(resizeTimer);
				}

				resizeTimer = requestAnimationFrame(() => {
					if (isMobileView()) {
						if (this.dateSelector.open) {
							this.setFixedStyles();
						} else {
							this.removeFixedStyles();
						}
					} else {
						this.removeFixedStyles();
					}
				});
			});
		}

		this.html.addEventListener('blurFields', event => this.blurFields());

		// Show and hide searchbox overlay depending on the opened / closed state of the searchFielsMulti respective modal
		EventDispatcher.addEventListener('searchFieldMulti.modalOpened', (isOpened) => {
			const overlay = document.getElementById('abSearchBox');
			if (isOpened) {
				overlay.classList.add('abSearchBox--show-overlay');
			} else {
				overlay.classList.remove('abSearchBox--show-overlay');
			}
		});
	}
}


export function scrollDatePickers (direction) {
	const disabled = 'abDateSelector__slider--disabled';
	const buttons = qsa('.abDateSelector__slider');
	const width = qs('.abDatePicker').offsetWidth;
	const inner = qs('.abDatePickers__inner');
	const scrolled = inner.scrollLeft;
	const maxScroll = inner.scrollWidth - inner.offsetWidth;

	if (typeof direction !== 'undefined') {
		const scrollLeft = (direction === 'right') ? (scrolled + width) : (scrolled - width);

		// Round up or down to the nearest multiple of width
		const rounded = Math.round(scrollLeft / width) * width;

		if (scrollLeft > 0) {
			buttons[0].classList.remove(disabled);
		} else {
			buttons[0].classList.add(disabled);
		}

		if (scrollLeft >= maxScroll) {
			buttons[1].classList.add(disabled);
		} else {
			buttons[1].classList.remove(disabled);
		}

		// inner.scrollTo({ left: scrollLeft, behavior: 'smooth' });
		inner.scrollTo({ left: rounded, behavior: 'smooth' });
	} else {
		if (scrolled > 0) {
			buttons[0].classList.remove(disabled);
		} else {
			buttons[0].classList.add(disabled);
		}

		if (scrolled >= maxScroll) {
			buttons[1].classList.add(disabled);
		} else {
			buttons[1].classList.remove(disabled);
		}
	}
}
